import { CircleSpinner } from '@newfront-insurance/core-ui';
import { LoginBoundary } from '@newfront-insurance/next-auth';
import type { ReactNode } from 'react';
import { Suspense } from 'react';

import { AccountContextProvider } from '@/client/providers/account-context';
import { AccountAccessBoundary, AccountDataProvider } from '@/client/providers/account-data';
import { AuthProvider } from '@/client/providers/auth';

interface Props {
  children: ReactNode;
}

export function AccountLayout({ children }: Props): JSX.Element {
  return (
    <LoginBoundary authProvider={AuthProvider}>
      <AccountContextProvider>
        <AccountAccessBoundary>
          <Suspense fallback={<CircleSpinner label="Loading account..." />}>
            <AccountDataProvider>{children}</AccountDataProvider>
          </Suspense>
        </AccountAccessBoundary>
      </AccountContextProvider>
    </LoginBoundary>
  );
}
