import { CircleSpinnerInline, Flexbox } from '@newfront-insurance/core-ui';

interface LoadingSpinnerProps {
  loadingText: string;
}

export function LoadingSpinner({ loadingText }: LoadingSpinnerProps): JSX.Element {
  return (
    <Flexbox height="100%" width="100%" alignItems="center" justifyContent="center">
      <CircleSpinnerInline label={loadingText} />
    </Flexbox>
  );
}
