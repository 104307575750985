import { ProgramType } from '@newfront-insurance/account-api';
import { Spinner } from '@newfront-insurance/core-ui';
import { PermissionError } from '@newfront-insurance/data-layer-client';
import type { RouterQueryOutput } from '@newfront-insurance/data-layer-server';
import { useQuery as useRouteQuery } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';

import { AuthSwapProvider } from './auth';
import { TRPCProvider } from './trpc';

import type { AppRouter } from '@/api/trpc';

type AccountResult = RouterQueryOutput<AppRouter, 'account.getAccount'>;

export interface AccountDataProviderInterface extends AccountResult {
  isPnC: boolean;
  isEb: boolean;
}

export const AccountDataProvider = createProvider((): AccountDataProviderInterface => {
  const accountUuid = useRouteQuery<string>('accountUuid');
  const { useSuspenseQuery } = useProvider(TRPCProvider);

  const { data: account } = useSuspenseQuery(['account.getAccount', { accountUuid }]);

  return {
    ...account,
    isPnC: account.programType === ProgramType.PROPERTY_AND_CASUALTY,
    isEb: account.programType === ProgramType.EMPLOYEE_BENEFITS,
  };
});

export function AccountAccessBoundary({ children }: { children: React.ReactNode }) {
  const accountUuid = useRouteQuery<string>('accountUuid');
  const { userDetails } = useProvider(AuthSwapProvider);
  const hasAccessToAccount = Boolean(userDetails?.accountUuid && userDetails.accountUuid === accountUuid);

  // if user details is not defined yet, that means the token swap
  // isn't ready yet and we can render the rest of the tree
  if (!userDetails) {
    return <Spinner />;
  }

  if (!hasAccessToAccount) {
    throw new PermissionError('You do not have permission to access this account.');
  }

  return children;
}
