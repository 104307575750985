import { isNotNil } from '@newfront-insurance/core';
import get from 'lodash/get';

export function getEntitiesWithUuid<T extends { uuid?: string }>(entities: T[]): (T & { uuid: string })[] {
  return entities.filter(({ uuid }) => isNotNil(uuid)).map((entity) => ({ ...entity, uuid: entity.uuid! }));
}

export function formatToShortNumber(value: number, options?: { toFixed?: boolean }) {
  const { toFixed = false } = options || {};
  let res = value;

  if (value >= 1000000) {
    res = value / 1000000;
    return toFixed ? `${res.toFixed(0)}M` : `${res}M`;
  }

  if (value >= 1000) {
    res = value / 1000;
    return toFixed ? `${res.toFixed(0)}K` : `${res}K`;
  }

  return value.toString();
}

/**
 * Useful for caching query data for a day.
 * This is useful for data that is unlikely to change often.
 * @example useLineOfCoverageDefinitions
 */
export const ONE_DAY_CACHE_STALE_OPTIONS = {
  gcTime: 24 * 60 * 60 * 1000, // 1 day
  staleTime: 24 * 60 * 60 * 1000, // 1 day
};

/**
 * Useful for avoiding refetching query data on mount or window focus
 */
export const AVOID_REFETCH_QUERY_OPTIONS = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export type TypeAndVersion = { type: string; version: number };

/**
 * Returns an array of unique types and versions from a list of items.
 */
export function getUniqueTypesAndVersions<T>(items: T[], typeKey: string, versionKey: string): TypeAndVersion[] {
  return Array.from(new Set(items.map((item) => `${get(item, typeKey)}-${get(item, versionKey)}`))).map((key) => ({
    type: key.split('-')[0],
    version: parseInt(key.split('-')[1], 10),
  }));
}

/**
 * Sorts an array of strings in alphanumeric order.
 * ie. ["a1", "a10", "a2"] => ["a1", "a2", "a10"]
 */
export function alphanumericSorter(a: string, b: string) {
  return a.localeCompare(b, 'en', { numeric: true });
}

export function generateWebsiteLink(url: string) {
  return url.startsWith('https://') || url.startsWith('http://') ? url : `https://${url}`;
}

export function isUuid(uuid: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(uuid);
}

export function accountDBAOrName(account: { dba?: string; name: string }): string {
  return account?.dba ?? account.name;
}
