import { ProgramType } from '@newfront-insurance/account-api';
import type { Route } from '@newfront-insurance/next-router-provider';
import { isUndefined, omitBy } from 'lodash';

import { getConfig } from '@/config';

export interface AccountRoute extends Record<string, string | undefined> {
  accountUuid: string;
}

export interface EmployeeWebPageRoute extends Record<string, string | undefined> {
  accountWebPortalUuid: string;
  type: string;
  country?: string;
}

export interface PartnerExperienceRoute extends Record<string, string | undefined> {
  partnerUuid: string;
  clientUuid?: string;
  certificateRequestUuid?: string;
}

interface PolicyRoute extends AccountRoute {
  policyUuid: string;
}

interface CertificateRoute extends AccountRoute {
  certificateId: string;
}

interface TaskRoute extends AccountRoute {
  taskUuid: string;
}

export interface InsurableItemsQuery extends Record<string, string | string[] | undefined> {
  accountUuid: string;
  type?: string;
  insurableItemUuid?: string;
}

export interface EbFilesQuery extends Record<string, string | string[] | undefined> {
  accountUuid: string;
  type?: string;
}

export interface ContractAssessmentRoute extends Record<string, string | undefined> {
  accountUuid: string;
  contractAssessmentUuid?: string;
  isNewReview?: string;
}

export function appRoute<Query extends Record<string, string | string[] | undefined>>(pathname: string) {
  return (query?: Query): Route => {
    return {
      pathname,
      query: omitBy(query, (value) => isUndefined(value) || value === '' || value === null),
    };
  };
}
const { LINKS } = getConfig();

export const baseRoute = '/[accountUuid]';
export const pncBaseRoute = `${baseRoute}/insurance`;
export const ebBaseRoute = `${baseRoute}/employee-benefits`;

export const employeeBaseRoute = '/employee/[accountWebPortalUuid]';

export const partnerExperienceBaseRoute = '/partners/[partnerUuid]';

export const manageTeamMembersPageRoutes: Record<ProgramType, (query: AccountRoute) => Route> = {
  [ProgramType.EMPLOYEE_BENEFITS]: appRoute<AccountRoute>(`${ebBaseRoute}/manage-team-members`),
  [ProgramType.PROPERTY_AND_CASUALTY]: appRoute<AccountRoute>(`${pncBaseRoute}/manage-team-members`),
};

export const pageRoutes = {
  clientHome: appRoute<AccountRoute>(`${pncBaseRoute}/dashboard`),
  employeeWebpage: appRoute<EmployeeWebPageRoute>(`${employeeBaseRoute}/[type]`),
  employeeWebpageDraft: appRoute<EmployeeWebPageRoute>(`${employeeBaseRoute}/draft/[type]`),
  partnerExperienceHome: appRoute<PartnerExperienceRoute>(`${partnerExperienceBaseRoute}`),
  partnerExperienceClientDashboard: appRoute<PartnerExperienceRoute>(
    `${partnerExperienceBaseRoute}?clientUuid=[clientUuid]`,
  ),
  partnerExperienceUploadNewCertificateWorkflow: appRoute<PartnerExperienceRoute>(
    `${partnerExperienceBaseRoute}/upload-new-certificate/[certificateRequestUuid]`,
  ),
  myServiceTeam: appRoute<AccountRoute>(`${baseRoute}/servicing-team`),
  settings: appRoute<AccountRoute>(`${baseRoute}/settings`),
  viewCertificates: appRoute<AccountRoute>(`${pncBaseRoute}/certificates`),
  viewCertificateDetails: appRoute<CertificateRoute>(`${pncBaseRoute}/certificates/[certificateId]`),
  viewPolicies: appRoute<AccountRoute>(`${pncBaseRoute}/policies`),
  viewPolicyDetails: appRoute<PolicyRoute>(`${pncBaseRoute}/policies/[policyUuid]`),
  viewClaims: appRoute<AccountRoute>(`${pncBaseRoute}/claims`),
  viewProjects: appRoute<AccountRoute>(`${baseRoute}/partners-management/projects`),
  viewPartners: appRoute<AccountRoute>(`${baseRoute}/partners-management/partners`),
  viewRiskProfiles: appRoute<AccountRoute>(`${baseRoute}/partners-management/risk-profiles`),
  viewProjectDetails: appRoute<AccountRoute>(`${baseRoute}/partners-management/projects/[projectUuid]`),
  viewPartnerDetails: appRoute<AccountRoute>(`${baseRoute}/partners-management/partners/[partnerUuid]`),
  viewAccountInsurableItemsDetails: appRoute<InsurableItemsQuery>(`${pncBaseRoute}/insurable-items`),
  viewManageTeamMembers: appRoute<AccountRoute>(`${baseRoute}/manage-team-members`), // We should use the ManageTeamMembersPageRoutes where possible
  viewPnCFiles: appRoute<AccountRoute>(`${pncBaseRoute}/files`),
  viewEBFiles: appRoute<EbFilesQuery>(`${ebBaseRoute}/files`),
  viewBilling: appRoute<AccountRoute>(`${pncBaseRoute}/billing`),
  viewContractAssessments: appRoute<AccountRoute>(`${pncBaseRoute}/contracts`),
  viewRiskPulse: appRoute<AccountRoute>(`${pncBaseRoute}/risk-pulse`),
  viewContractAssessment: appRoute<ContractAssessmentRoute>(`${pncBaseRoute}/contracts`),
  viewPlans: appRoute<AccountRoute>(`${ebBaseRoute}/plans`),
  viewVendorSearch: appRoute<AccountRoute>(`${LINKS.CLIENT_DASH_APP}/vendors/search`),
  viewResources: appRoute<AccountRoute>(`${ebBaseRoute}/resources`),
  viewTask: appRoute<TaskRoute>(`${pncBaseRoute}/tasks/[taskUuid]`),
  viewOverview: appRoute<AccountRoute>(`${ebBaseRoute}/overview`),
  viewFAQs: appRoute<AccountRoute>(`${ebBaseRoute}/faq`),
  viewCountryReports: appRoute<AccountRoute>(`${ebBaseRoute}/country-reports`),
};

export const PROGRAM_TYPE_TO_ROUTE: Record<ProgramType, string> = {
  PROPERTY_AND_CASUALTY: pncBaseRoute,
  EMPLOYEE_BENEFITS: ebBaseRoute,
};
