import { isUuid } from '@newfront-insurance/admin-ui';
import { Spinner } from '@newfront-insurance/core-ui';
import { NotFoundError } from '@newfront-insurance/data-layer-client';
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import * as React from 'react';
import { useEffect } from 'react';

import { AuthSwapProvider } from './auth';

interface AccountContextProviderProps {
  children: React.ReactNode;
}

/**
 * This provider keeps track of the current account. It will save the recently viewed
 * account to localStorage and it will set the auth swap context, which triggers
 * the token to be reloaded.
 */
export function AccountContextProvider({ children }: AccountContextProviderProps): JSX.Element {
  const accountUuid = useQuery<string>('accountUuid');
  const { setSwapContext, swapContext } = useProvider(AuthSwapProvider);

  useEffect(() => {
    if (!swapContext && isUuid(accountUuid)) {
      setSwapContext({ newfrontAccountUuid: accountUuid });
    }
  }, [accountUuid, setSwapContext, swapContext]);

  if (!isUuid(accountUuid)) {
    throw new NotFoundError('Page not found');
  }

  if (!swapContext) {
    return <Spinner />;
  }

  return <>{children}</>;
}
